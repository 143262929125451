<template>
  <div class="clue">
    <div class="container">
      <el-card shadow="never">
        <el-form>
          <el-form-item label="所在地区">
            <city-select
              ref="child"
              :province="queryInfo.province"
              :city="queryInfo.city"
              :district="queryInfo.district"
              @searchProvince="searchProvince"
              @searchCity="searchCity"
              @searchDistrict="searchDistrict"
            />
          </el-form-item>
          <el-form-item label="开发进度">
            <el-radio-group>
              <el-radio-button> 全部 </el-radio-button>
              <el-radio-button>背景信息</el-radio-button>
              <el-radio-button>背景信息</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <div class="header_direction"></div>
          <div class="newClue">
            <div>
              <el-button type="primary">新增</el-button>
              <el-button>批量导入</el-button>
              <el-button>批量分配</el-button>
            </div>
            <div class="search_right">
              <el-input
                placeholder="请输入合作渠道名称"
                class="input-with-select"
                style="width: 500px"
                clearable
              >
                <el-button slot="append">搜索</el-button>
              </el-input>
            </div>
          </div>
        </el-form>
      </el-card>
      <!-- 数据统计与排序 -->
      <div class="container_count">
        <div style="padding-right: 30px">
          <span
            >当前条件下共查出<span style="color: #0099ff">{{ total }}</span
            >家待开发合作伙伴</span
          >
        </div>
        <div style="display: flex; align-items: center; margin-right: 30px">
          按新增时间
          <div class="main">
            <span
              class="arrUp"
              @click="clickUp1"
              :style="{ 'border-bottom-color': orderinfo.color1 }"
            ></span>
            <span
              class="arrDown"
              @click="clickDown2"
              :style="{ 'border-top-color': orderinfo.color2 }"
            ></span>
          </div>
        </div>
      </div>
      <!-- 卡片列表 -->
      <el-card shadow="never">
        <div class="container_conter">
          <div>
            <el-checkbox></el-checkbox>
          </div>
          <!-- 企业图片区域 -->
          <div class="channel_left">
            <div class="channel_left_img">
              <img src="" />
            </div>
            <div class="channel_left_conter">
              <div class="channel_left_conter_text">行业协会名称</div>
              <div class="channel_left_conter_phone">
                <span style="margin-right: 20px">张三</span>
                <span>123456789125</span>
              </div>
              <div class="channel_left_conter_phone">
                湖南<span>-</span>长沙<span>-</span>开福区
              </div>
            </div>
          </div>
          <!-- 进度条 -->
          <div class="progress_box">
            <div class="progress">
              <el-steps align-center>
                <el-step>
                  <template slot="title">
                    <div class="item">背景信息</div>
                  </template>

                  <template slot="description">
                    <div class="time">2024-04-05</div>
                  </template>
                </el-step>
                <el-step>
                  <template slot="title">
                    <div class="item">合作触达</div>
                  </template>

                  <template slot="description">
                    <div class="time">2024-04-05</div>
                  </template>
                </el-step>
                <el-step>
                  <template slot="title">
                    <div class="item" @click="changeUnit">合作试点</div>
                  </template>

                  <template slot="description">
                    <div class="time">2024-04-05</div>
                  </template>
                </el-step>
                <el-step>
                  <template slot="title">
                    <div class="item">合作签约</div>
                  </template>

                  <template slot="description">
                    <div class="time">2024-04-05</div>
                  </template>
                </el-step>
              </el-steps>
            </div>
          </div>
          <!-- 右侧按钮 -->
          <div class="card_right">
            <!-- <div class="list_distribution">
              <img
                v-if="item.userName"
                style="width: 28px; height: 28px"
                src="../../assets/image/男生头像 1.png"
              />
              <span class="distribution_name"></span>
            </div> -->
            <div class="btn_list row">
              <div class="list_distribution">
                <img src="../../../assets/image/Group 3803.png" />
                <span class="distribution">待分配</span>
              </div>
              <span class="click" style="color: #4e93fb">开发日志</span>
              <span class="click" style="color: #4e93fb" @click="changePartners">转化合作伙伴</span>
              <span class="click" style="color: #4e93fb">编辑</span>
            </div>
            <div style="position: relative; top: 30px" class="row">
              <span class="operate">操作人：</span>
              <div>
                <span class="operate">最新日志更新时间：</span>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <!-- 分页区域 -->
    <div class="new_page">
      <el-pagination
        :current-page="1"
        :page-sizes="[4, 10, 30]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="1"
      />
    </div>
    <!-- 合作试点弹窗 -->
    <el-dialog :visible.sync="unitDialog" width="20%" center>
      <div class="my-custom-class">该状态变更为合作试点</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="unitDialog = false">取 消</el-button>
        <el-button type="primary" @click="unitDialog = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 转化合作伙伴弹窗 -->
    <el-dialog :visible.sync="partnersDialog" width="20%" center>
      <div class="my-custom-class">已转化为合作伙伴</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="partnersDialog = false">取 消</el-button>
        <el-button type="primary" @click="partnersSave">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 合作签约弹窗 -->
    <el-dialog class="signing" :visible.sync="signingDialog" width="30%" center>
      <span slot="title" class="custom-dialog-title">
        <div class="left-text">合作签约</div>
      </span>
      <div>
        <el-form>
          <el-form-item label="内容附件" prop="">
            <div class="row">
              <div class="input_upload_box">
                <el-input
                  style="width: 440px; height: 38px"
                  placeholder="请添加内容附件"
                  readonly
                ></el-input>
                <UploadFile
                  ref="upload_child"
                  class="upload_box"
                  @change="uploadFileSuccess"
                ></UploadFile>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="signingDialog = false">取 消</el-button>
        <el-button type="primary" @click="signingSave">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
import CitySelect from '@/components/CitySelect.vue'
import cos from '../../../utils/cos.js'

const defaultQueryInfo = Object.freeze({
  pageNum: 1,
  pageSize: 10,
  orders: 1, // 排序
  city: '', //城市
  province: '', //省份
  district: '', // 区县
  keys: '' //线索标题/企业名称
})
const defaultOrder = Object.freeze({
  color1: '',
  color2: '#C6C6C6'
})
export default {
  components: { CitySelect },
  name: 'development',
  data() {
    return {
      //查询参数
      queryInfo: { ...defaultQueryInfo },
      //排序字段
      orderinfo: { ...defaultOrder },
      total: 0,
      unitDialog: false, // 合作试点弹窗
      partnersDialog: false, // 转化合作伙伴弹窗
      signingDialog: true // 合作签约弹窗
    }
  },
  methods: {
    //返回省
    searchProvince(val) {
      this.queryInfo.pageNum = 1
      this.queryInfo.province = val
    },
    //返回城市
    searchCity(val) {
      this.queryInfo.city = val
    },
    // 返回区域
    searchDistrict(val) {
      this.queryInfo.district = val
    },
    //排序按创建时间
    clickUp1() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '1'
      this.orderinfo.color1 = '#0099ff'
      this.orderinfo.color2 = ''
    },
    clickDown2() {
      this.orderinfo = { ...defaultOrder }
      this.queryInfo.orders = '2'
      this.orderinfo.color1 = ''
      this.orderinfo.color2 = '#0099ff'
    },
    // 上传成功后的回调
    uploadFileSuccess(val) {
      console.log(val);
    },
    // 移除附件
    removeFile() {
      // this.form.policyInterpretationFile = ''
      this.$refs.upload_child.clear()
    },
    // 变更合作试点
    changeUnit() {
      this.unitDialog = true
    },
    // 转化合作伙伴
    changePartners() {
      this.partnersDialog = true
    },
    // 转化合作伙伴确定
    partnersSave() {
      this.partnersDialog = false
    },
    // 合作签约
    changeSigning() {
      this.signingDialog = true
    },
    // 合作签约确定
    signingSave() {
      this.signingDialog = false
    }
  }
}
</script>
  
<style lang="less" scoped>
.clue {
  overflow: auto;
  .container {
    height: calc(100vh - 150px);
    overflow: auto;
    &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
    .header_direction {
      width: 100%;
      border: 1px dashed #ebeef5;
      margin-bottom: 15px;
    }
    .newClue {
      display: flex;
      justify-content: space-between;
      .el-button--primary {
        width: 98px;
      }
      .search_right {
        width: 500px;
        margin-right: 40px;
        ::v-deep .el-input-group__append {
          border-right: 0;
        }
        ::v-deep .el-button {
          border: #448aff 1px solid;
          color: #fff;
          background-color: #448aff;
        }
      }
    }
    .container_count {
      display: flex;
      align-items: center;
      padding: 10px 0px;
      font-size: 16px;
      color: #524f4f;
      .main {
        display: flex;
        flex-direction: column;
      }

      .arrUp {
        width: 0;
        height: 0;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        border-bottom: 8px solid #c6c6c6;
        cursor: pointer;
      }

      .arrDown {
        width: 0;
        height: 0;
        margin-top: 2px;
        border-right: 8px solid transparent;
        border-left: 8px solid transparent;
        border-top: 8px solid #c6c6c6;
        cursor: pointer;
      }
    }
    .container_conter {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .channel_left {
        margin-left: 10px;
        display: flex;
        width: 20%;
        .channel_left_img {
          flex-shrink: 0;
          width: 136.82px;
          height: 88px;
          margin-right: 10px;
          border-radius: 8px;
          img {
            width: 136.82px;
            height: 88px;
            border-radius: 8px;
          }
        }
        .channel_left_conter {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .channel_left_conter_text {
            font-weight: 600;
            font-size: 18px;
          }
          .channel_left_conter_phone {
            font-size: 14px;
            color: #797171;
          }
        }
      }
      .progress_box {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        width: 60%;

        .progress {
          width: 100%;
          box-sizing: border-box;
          height: 98px;
          border-radius: 5px;
          display: flex;
          align-items: center;

          /deep/.el-steps--horizontal {
            flex: 1;
          }

          .item {
            color: #9299a5;
            font-size: 14px;
            cursor: pointer;
          }

          .mapping {
            line-height: 1;
            display: flex;
            align-items: center;
            height: 38px;
            justify-content: center;
          }

          .have_not_started {
            color: #333333;
          }

          .time {
            color: #333333;
          }

          /deep/.el-step__icon-inner {
            display: none;
          }

          /deep/.el-step__icon {
            width: 16px;
            height: 16px;
          }

          /deep/.el-step__line {
            top: 8px;
          }

          // 已完成
          /deep/
            .el-steps.el-steps--horizontal
            .el-step.is-horizontal.is-center
            .el-step__head.is-finish
            .el-step__icon {
            z-index: 1;
            color: #448aff;
            border-color: #448aff;
          }

          // 已完成线
          /deep/
            .el-steps.el-steps--horizontal
            .el-step.is-horizontal.is-center
            .el-step__head.is-finish
            .el-step__line {
            border-color: rgba(23, 77, 122, 0.2);
            background-color: rgba(23, 77, 122, 0.2);
          }
        }
      }
      .card_right {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 25%;
        .frozen {
          color: #fd5469;
          cursor: pointer;
        }
        .row {
          margin-bottom: 10px;
        }
        .btn_list {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-around;
          position: relative;
          margin-left: 50px;
          top: 20px;
          margin-bottom: 20px;
          .click {
            cursor: pointer;
          }
          .list_distribution {
            display: flex;
            align-items: center;
          }
          .distribution {
            margin-left: 5px;
            cursor: pointer;
            color: #fba24e;
            border-bottom: 1px solid #fba24e;
          }
          .distribution_name {
            margin-left: 5px;
            cursor: pointer;
            color: rgba(33, 122, 247, 1);
            border-bottom: 1px solid #448aff;
          }
        }
        .operate {
          display: flex;
          justify-content: right;
          font-size: 10px;
          color: #999;
          margin-bottom: 10px;
        }
      }
    }
  }
  .new_page {
    margin: 20px auto;
    width: 100%;
    text-align: center;
  }
}
.my-custom-class {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 18px;
}
::v-deep .signing {
  .el-dialog {
    .el-dialog__header {
      padding: 20px 0;
    }
  }
}
.custom-dialog-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
}
/* 控制最左边的文本样式 */
.left-text {
  padding-left: 10px;
  border-left: 3px solid #448aff;
  font-family: '微软雅黑';
}
.row {
  width: 100%;
  display: flex;
}
.input_upload_box {
  border: 1px solid #c4c4c4;
  width: 600px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 2px;
  padding-right: 10px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  overflow: hidden;
  i {
    cursor: pointer;
    color: #999;
  }
  // .txt {
  //   width: 100%;
  //   font-size: 14px;
  //   line-height: 40px;
  //   color: #999;
  //   overflow: hidden;
  //   white-space: nowrap;
  //   text-overflow: ellipsis;
  // }
  .upload_box {
    margin-left: auto;
  }
  ::v-deep .el-input__inner {
    height: 38px;
    line-height: 38px;
    border: none;
  }
}
</style>